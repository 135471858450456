import Vue from 'vue'
import App from './App.vue'

// Append VIEWER style only on VIEWER website
let isViewer = document && document.location && (document.location.host.includes('.smuc.io') || document.location.host.includes('localhost'))
if (
  isViewer
  && (
    typeof window.smucio == 'undefined'
    || (
      typeof window.smucio != 'undefined'
      && typeof window.smucio.styled == 'undefined'
    )
  )
) {
  window.smucio = window.smucio || { apikeys: [] };
  window.smucio.styled = true

  require('./assets/scss/styles.scss')
}

Vue.config.productionTip = false

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL

let SmucioStreamview;
if (process.env.NODE_ENV === 'production') {
  require('smucio-streamview/dist/smucio-streamview.hashed.css');
  SmucioStreamview = require('smucio-streamview/dist/smucio-streamview.common.hashed.js');
}
else {
  require('smucio-streamview/dist/smucio-streamview.css');
  SmucioStreamview = require('smucio-streamview/dist/smucio-streamview.common.js');
}
Vue.use(SmucioStreamview.default)

if (window && window.location && window.location.pathname === '/stream/' && window.location.search) {
  let parameterBag = new URLSearchParams(window.location.search)
  if (parameterBag.has('apikey')) {
    window.smucio = window.smucio || { apikeys: [] };
    window.smucio.apikeys.push({
      key: decodeURIComponent(parameterBag.get('apikey')),
      mode: 'viewer',
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.smucio.apikeys.forEach(item => {
    if (item.loaded) {
      return;
    }
    item.loaded = true;

    let elid = 'smuc-player';
    if (item.mode !== 'viewer') {
      elid += '--' + item.key;
    }

    let $el = null;
    if (item.mode === 'overlay') {
      $el = document.createElement('div');
      $el.id = elid;
      document.body.appendChild($el);
    }
    else {
      $el = document.getElementById(elid);
    }
    if (!$el) {
      return;
    }

    // H4ck: Multiple embeds with same apikey.
    if (item.mode !== 'viewer') {
      $el.id += '-' + Math.random().toString().substring(2, 6);
    }

    function buildApp() {
      class ClonedVue extends Vue { }

      new ClonedVue({
        data: {
          'smucapikey': item.key,
          'smucplayermode': item.mode,
          'optimizeForAd': typeof item.optimizeForAd == 'number' && item.optimizeForAd == 1,
          'clickTracker': decodeURIComponent(decodeURIComponent($el.getAttribute('data-click-tracker'))),
          chat: {
            currentUserId: null,
            messages: [],
            animatedMessages: [],
            pinnedMessage: null,
            hasMore: true,
            isLoading: false,
            inited: false,
            firstMessagesReceived: false,
            bannedUsers: [],
          },
        },
        render: h => h(App)
      }).$mount($el);
    }

    new IntersectionObserver((entries, observer) => {
      const isVisible = entries[0].isIntersecting;
      if (isVisible) {
        observer.disconnect();
        buildApp();
      }
    }, { root: null, rootMargin: '200px' }).observe($el);
  });
});
