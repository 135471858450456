<template>
  <div :id="`smuc-player--${apikey}`">
    <stream-view
      v-if="stream !== null && isAllowedToBeDisplayed"
      v-show="isVisible"
      :error-message="errorMessage"
      :is-joined="isJoined"
      :language="stream.streamview_language"
      :mode="playerMode"
      :stream="stream"
      :ws-client="wsClient"
      :player-domain="playerDomain"
      :stream-thumbnail-domain="streamThumbnailDomain"
      :optimize-for-ad="optimizeForAd || stream.optimize_for_ad"
      @videoLoaded="() => isReady = true"
      @play="onPlay"
      @pause="onPause"
    />

    <subscribe-notification
      v-if="apikey && showCountdownBox && !expiredCountdownBox"
      :start-date="streamStartDateOriginal"
      :button-color="stream.button_color"
      :text-color="stream.text_color"
      :brand-logo="streamBrandLogo"
      :cover-url="coverUrl"
      :apikey="apikey"
      :language="stream.streamview_language"
      :privacy-policy-url="stream.privacy_policy_url"
      @subscribed="isSubscribedToNotification"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { WsClient, WsClientEvents, HasNoFreeViewerCapacityError } from 'smucio-ws-client';
import browserMixin from '@/mixins/browser';
import { Media, StreamData } from 'smucio-stream-entities'

const mobileBreakdownAt = 920 - 1

const isObj = (o) => Object.prototype.toString.call(o) == '[object Object]'

const _debounce = function _debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args); }, timeout)

    return {
      cancel() {
        clearTimeout(timer)
      }
    }
  };
}

export default {
  name: 'SmucPlayer',
  mixins: [browserMixin],
  data() {
    return {
      wsClient: null,
      playerMode: this.$parent.smucplayermode,
      playerModeFromEmbedCode: null,
      optimizeForAd: this.$parent.optimizeForAd,
      apikey: this.$parent.smucapikey,
      dateNow: new Date(),
      isReady: false,
      stream: null,
      vodChapterNumber: 0,
      isJoined: false,
      errorMessage: null,
      trappedViewport: null,
      isAllowedToBeDisplayed: false,
      subscribedForNotification: false,
      expiredCountdownBox: false,
      pauseStatusTO: null,
      widthTimeout: null,
    }
  },
  computed: {
    playerDomain() {
      return process.env.VUE_APP_PLAYER_DOMAIN;
    },
    streamThumbnailDomain() {
      return process.env.VUE_APP_STREAM_THUMBNAIL_DOMAIN;
    },
    isVisible() {
      if (this.playerMode === 'embedded' || this.playerMode === 'viewer') {
        return true;
      }

      return this.isReady;
    },
    // @MARK - EZEKET NEM KELL ÁTVINNI A STREAMVIEW-ba, csak a player használta!
    // streamStartDate() {
    //   return this.stream.end_date && !isNaN(Date.parse(this.stream.start_date)) ? new Date(Date.parse(this.stream.start_date)) : null
    // },
    // streamEndDate() {
    //   return this.stream.end_date && !isNaN(Date.parse(this.stream.end_date)) ? new Date(Date.parse(this.stream.end_date)) : null
    // },
    streamStartDateOriginal() {
      return !isNaN(Date.parse(this.stream.start_date)) ? new Date(Date.parse(this.stream.start_date)) : null
    },
    showCountdownBox() {
      return isObj(this.stream)
        && this.stream.show_countdown_box == true
        && this.playerMode === 'overlay'
        && !this.subscribedForNotification
        && this.streamStartDateOriginal != null
        && this.dateNow < this.streamStartDateOriginal
    },
    windowWidth: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.widthTimeout) {
          clearTimeout(this.widthTimeout)
        }

        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 500);
      }
    },
    isMobileBreakpoint() {
      return this.windowWidth <= mobileBreakdownAt
    },
    streamType() {
      switch (parseInt(this.stream.type, 10)) {
        case 0: return 'live'
        case 1: return 'playback'
        case 2: return 'embed'
        default: return 'live'
      }
    },
    liveStatus() {
      if (!this.stream) {
        return 'unknown'
      }

      const status = typeof this.stream.stream_status === 'number' || /^\d+$/.test(this.stream.stream_status) ? parseInt(this.stream.stream_status, 10) : -1
      switch (status) {
        case 0: return 'waiting'
        case 1: return 'live'
        case 2: return 'paused'
        case 3: return 'closed'
        default: return 'unknown'
      }
    },
    isLive() {
      return this.streamType == 'live' && this.liveStatus !== 'closed'
    },
    isPlayback() {
      return this.streamType == 'playback' && this.liveStatus !== 'closed'
    },
    coverLandscape() {
      return isObj(this.stream.cover) && typeof this.stream.cover.url == 'string' ? this.stream.cover : null
    },
    coverPortrait() {
      return isObj(this.stream.cover_portrait) && typeof this.stream.cover_portrait.url == 'string' ? this.stream.cover_portrait : null
    },
    coverUrl() {
      let url;
      if (this.coverPortrait != null && (this.coverOrientation == 'portrait' || this.coverLandscape == null)) {
        url = this.coverPortrait.url;
      }
      else if (this.coverLandscape != null) {
        url = this.coverLandscape.url;
      }

      return typeof url == 'string' && url.length ? url : null;
    },
    coverOrientation() {
      if (
        this.isMobileBreakpoint
        && !this.isMinimized
        && this.theKind !== 'vod'
      ) {
        return 'portrait';
      }

      if (this.theVideoOrientation === 'custom') {
        return this.maxHeight > this.maxWidth ? 'portrait' : 'landscape';
      }

      return this.theVideoOrientation;
    },
  },
  watch: {
    liveStatus(newVal) {
      // @TODO - 'closed' status is nearly impossible to get, but 'paused' can be relevant..
      if (newVal === 'closed' && this.playerMode === 'overlay') {
        this.handleDestroy()
      }
    },
    showCountdownBox: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          const diffSeconds = Math.floor(this.stream.start_date - new Date())
          setTimeout(() => this.expiredCountdownBox = true, diffSeconds)
        }
      }
    }
  },
  beforeCreate() {
    this.$root.$bus = new Vue();
  },
  created() {
    console.log(`[SmucPlayer] created :: apikey #${this.apikey} - mode: ${this.playerMode}`)

    if (this.apikey === null) {
      console.error('[SmucPlayer] Missing apikey parameter.')
      return
    }

    this.fetchData();
    this.loadArcheo();

    // this.$root.$bus.$on('streamview.products.toggle.status', this.eventOnProductsToggleStatus);
    // this.$root.$bus.$on('streamview.product.feed.click', this.eventOnProductFeedClicked);
    // this.$root.$bus.$on('streamview.disconnected', this.eventOnStreamviewDisconnected); // @deprecated
    this.$root.$bus.$on('streamview.close', this.eventOnStreamviewClose);
    this.$root.$bus.$on('streamview.player.ended', this.eventOnStreamviewEnded);
    // this.$root.$bus.$on('streamview.chat.status', this.eventOnStreamviewChatStatus);
    // this.$root.$bus.$on('streamview.mute.status', this.eventOnStreamviewMuteStatus);
    this.$root.$bus.$on('streamview.minimize.status', this.eventOnStreamviewMinimizeStatus);
    // this.$root.$bus.$on('streamview.fullscreen.status', this.eventOnStreamviewFullscreenStatus);

    this.trapMetaViewport();

    if (window && window.localStorage) {
      this.subscribedForNotification = localStorage.getItem('smuc_subscribed_for_' + this.apikey) ? true : false
    }
  },
  mounted() {
    if (window && document && this.isIosSafari && this.browserVisualViewportSupported) {
      window.visualViewport.addEventListener('resize', _debounce(this.onResizeIosSafari, 250), { passive: true });
      window.dispatchEvent(new Event('resize'));
    }

    window.addEventListener('resize', this.eventOnResize)
    this.eventOnResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.eventOnResize)

    if (this.wsClient) {
      this.wsClient.disconnect();
    }

    // this.$root.$bus.$off('streamview.products.toggle.status', this.eventOnProductsToggleStatus);
    // this.$root.$bus.$off('streamview.product.feed.click', this.eventOnProductFeedClicked)
    // this.$root.$bus.$off('streamview.disconnected', this.eventOnStreamviewDisconnected); // @deprecated
    this.$root.$bus.$off('streamview.close', this.eventOnStreamviewClose);
    this.$root.$bus.$off('streamview.player.ended', this.eventOnStreamviewEnded);
    // this.$root.$bus.$off('streamview.chat.status', this.eventOnStreamviewChatStatus);
    // this.$root.$bus.$off('streamview.mute.status', this.eventOnStreamviewMuteStatus);
    this.$root.$bus.$off('streamview.minimize.status', this.eventOnStreamviewMinimizeStatus);
    // this.$root.$bus.$off('streamview.fullscreen.status', this.eventOnStreamviewFullscreenStatus);

    if (window && document && this.isIosSafari && this.browserVisualViewportSupported) {
      window.visualViewport.removeEventListener('resize', this.onResizeIosSafari, { passive: true });
    }

    // Reset parent site's original meta viewport value if available..
    if (this.trappedViewport !== null) {
      const $viewport = document.querySelector('meta[name="viewport"]')
      if ($viewport) {
        $viewport.setAttribute('content', this.trappedViewport)
      }
    }
  },
  methods: {
    fetchData() {
      window.fetch(`${process.env.VUE_APP_SMUC_STREAMS_URL}${this.apikey}.smc?${(new Date()).getTime()}`)
        .then(response => response.json())
        .then(data => {
          if (typeof data.feed !== 'undefined' && Array.isArray(data.feed)) {
            for (const _feed of data.feed) {
              _feed.show_in_feed = typeof _feed.show_in_feed === 'undefined' || _feed.show_in_feed;
            }
          }

          const streamData = new StreamData(data)
          streamData.logo = new Media(0, data.logo_url)
          streamData.cover = data.cover !== null ? new Media(0, data.cover) : null
          streamData.cover_portrait = data.cover_portrait !== null ? new Media(0, data.cover_portrait) : null
          streamData.agency_logo = data.agency_logo !== null ? new Media(0, data.agency_logo) : null
          streamData.photoad_photo = data.photoad_photo !== null ? new Media(0, data.photoad_photo) : null
          streamData.apikey = this.apikey
          if (streamData.optimize_for_ad) {
            streamData.ad_size = typeof streamData.ad_size == 'string' && streamData.ad_size.includes('x') ? streamData.ad_size : '300x600'
          } else if (this.optimizeForAd && typeof streamData.ad_size !== 'string') {
            streamData.optimize_for_ad = true
            streamData.ad_size = '300x600'
          }

          // Backward-compatibility
          if (typeof data.rounded_corners == 'undefined') {
            streamData.rounded_corners = 1;
          }

          this.stream = streamData
          console.log('[SMUC] Player', this.stream)

          this.$nextTick(() => {
            this.initWsConnection();
          });

          this.customizeViewerBackground(data.ad_bg_url)

          // @TODO: Should we check to isVOD status here?!
          if (this.liveStatus === 'closed') {
            this.isAllowedToBeDisplayed = true
            return;
          }

          this.processStreamData()
        })
        .catch(e => {
          console.error('[SmucPlayer] Failed to retrieve Stream data.', e)
        })
    },
    processStreamData() {
      if (!this.checkFrequencyOfOccurrence()) {
        console.info('[SmucPlayer] Player cannot be displayed due to frequency of occurrence of the stream.')
        return
      }

      this.isAllowedToBeDisplayed = true

      this.updateFrequencyOfOccurrence()
    },
    checkFrequencyOfOccurrence() {
      if (this.playerMode !== 'overlay') {
        return true
      }

      const foo = this.stream.frequency || 'always'
      const lskey = `smuc_frequency_opened_at_${this.apikey}`
      const freq = window.localStorage && window.localStorage.getItem(lskey) !== null ? window.localStorage.getItem(lskey) : null
      // Display player when no localstorage record was found
      // OR stored value is not the 13 digits long value of Date.getTime()
      // OR we forced to display always.
      if (
        freq === null
        || /^\d{13}$/.exec(freq) === null
        || foo === 'always'
      ) {
        return true
      }

      const freqDate = new Date(parseInt(freq, 10))

      if (foo === 'close') {
        // Higher freqDate is possible due to updateFrequencyOfOccurrence(true)
        // in eventOnStreamviewClose() method. This is the case when
        // user clicks on the [X] button.
        return freqDate < new Date()
      }

      let dateOffset = 0
      if (foo === 'hourly') {
        dateOffset = 1 * 3600 * 1000
      } else if (foo === 'daily') {
        dateOffset = 24 * 3600 * 1000
      } else if (foo === 'weekly') {
        dateOffset = 7 * 24 * 3600 * 1000
      }
      const dateToReopen = new Date(freqDate.getTime())
      dateToReopen.setTime(dateToReopen.getTime() + dateOffset)

      if (new Date() >= dateToReopen) {
        return true
      }

      return false
    },
    updateFrequencyOfOccurrence(closePermanently = false) {
      if (!window.localStorage) {
        return
      }

      const toDateTime = closePermanently ? (new Date('2087-03-01 06:00:00')).getTime() : (new Date()).getTime()
      const lskey = `smuc_frequency_opened_at_${this.apikey}`
      window.localStorage.setItem(lskey, toDateTime)
    },
    initWsConnection() {
      this.wsClient = new WsClient({
        wsUrl: process.env.VUE_APP_CHAT_WS_URL,
        apiKey: this.apikey,
        serverPublicKey: process.env.VUE_APP_WS_MESSAGE_SERVER_PUBLIC_KEY,
        clientPrivateKey: process.env.VUE_APP_WS_MESSAGE_CLIENT_PRIVATE_KEY,
      });
      this.wsClient.addEventListener(WsClientEvents.ERROR, (err) => {
        if (err instanceof HasNoFreeViewerCapacityError) {
          this.errorMessage = 'You cannot join at this time because the broadcast has reached its maximum audience.';
        }
      });
      this.wsClient.addEventListener(WsClientEvents.JOINED, () => {
        this.isJoined = true;
      });
      this.wsClient.addEventListener(WsClientEvents.STREAM_STATUS_CHANGED, (status) => {
        this.stream.stream_status = status;

        if (this.liveStatus !== 'live') {
          this.wsClient.pause();
        }
      });
    },
    destroy() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    onPlay() {
      if (this.wsClient) {
        this.wsClient.play();
        this.wsClient.getInfo();
      }
    },
    onPause() {
      if (this.wsClient) {
        this.wsClient.pause();
      }
    },
    // eventOnStreamviewDisconnected() {
    //   console.log('Streamview Disconnected!')
    //   this.handleDestroy()
    // },
    // eventOnProductFeedClicked(e) {
    //   console.log('Product Clicked!', e)
    // },
    eventOnStreamviewClose() {
      console.log('Streamview Close!')
      if (this.stream.frequency === 'close') {
        this.updateFrequencyOfOccurrence(true)
      }

      this.destroy()
    },
    // eventOnStreamviewChatStatus(e) {
    //   console.log('Streamview Chat!', e)
    // },
    // eventOnStreamviewMuteStatus(e) {
    //   console.log('Streamview Mute!', e)
    // },
    eventOnStreamviewMinimizeStatus(e) {
      console.log('Streamview Minimize!', e)
      this.isMinimized = e
    },
    // eventOnStreamviewFullscreenStatus(e) {
    //   console.log('Streamview Fullscreen!', e)
    // },
    // eventOnProductsToggleStatus(e) {
    //   console.log('Streamview Toggle!', e)
    // },
    eventOnStreamviewEnded() {
      console.log('Streamview Ended!')
      this.handleDestroy()
    },
    handleDestroy() {
      if (this.isLive) {
        if (this.playerMode === 'viewer') {
          const $msg = document.createElement('div')
          $msg.classList.add('livestream-has-ended')
          $msg.innerHTML = 'The live stream has been over.<br>Thank you for your participation!'
          document.body.appendChild($msg)
        }


        if (this.playerMode === 'overlay') {
          this.destroy()
        }

        return
      }

      if (this.isPlayback) {
        if (this.playerMode === 'overlay') {
          this.destroy()
        }

        return
      }
    },
    onResizeIosSafari() {
      if (this.playerMode !== 'viewer') {
        return
      }

      const measuredHeight = document.documentElement ? document.documentElement.clientHeight : window.innerHeight;
      document.querySelector('html').style.height = measuredHeight + 'px';
      document.body.style.height = measuredHeight + 'px';
      window.scrollTo({
        top: 1,
        left: 0
      })
    },
    trapMetaViewport() {
      if (this.playerMode === 'viewer') {
        return
      }

      const $viewport = document.querySelector('meta[name="viewport"]')
      if ($viewport === null) {
        const $meta = document.createElement('meta')
        $meta.setAttribute('name', 'viewport')
        $meta.setAttribute('content', 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,shrink-to-fit=no')
        document.head.appendChild($meta)
        return
      }

      let content = $viewport.getAttribute('content')
      this.trappedViewport = content
      if (content.includes('maximum-scale')) {
        content = content.replace(/(maximum-scale=([0-9](\.?[0-9])?),?)/, 'maximum-scale=1.0,').replace(/,$/, '')
        $viewport.setAttribute('content', content)
      } else {
        $viewport.setAttribute('content', content + ',maximum-scale=1.0')
      }
    },
    loadArcheo() {
      let archeo = { app: process.env.VUE_APP_ARCHEO_APP, queue: [] };
      this.$root.$bus.$on('aq', function (args) { archeo.queue.push(args) });

      const script = document.createElement('script');
      script.src = 'https://archeo.smuc.io/a.js?vn=smcajs';
      script.defer = true;
      script.onload = () => {
        archeo = window.smcajs(archeo);
        archeo.pageview({ content_id: this.apikey });
      }
      document.head.appendChild(script);
    },
    checkCloudflareM3u8File(url) {
      return new Promise(resolve => {
        if (!url) {
          url = this.theVideoSource;
        }
        if (this.theVideoSource.indexOf('.m3u8') === -1) {
          resolve();
          return;
        }

        console.log('Checking Cloudflare m3u8 file: ' + url);

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = async () => {
          if (xhr.readyState === 4) {
            if (xhr.responseText.trim().length === 0) {
              await this.checkCloudflareM3u8File(url);
            }

            if (url === this.theVideoSource) {
              let m3u8Lines = xhr.responseText.split('\n').filter(line => line[0] !== '#').map(line => line.trim());
              if (m3u8Lines.length === 0) {
                await this.checkCloudflareM3u8File(url);
              }

              for (let i = 0; i < m3u8Lines.length; ++i) {
                let nextUrl = this.theVideoSource.split('/');
                nextUrl.pop();
                nextUrl = nextUrl.join('/') + '/' + m3u8Lines[i];

                await this.checkCloudflareM3u8File(nextUrl);
              }
            }

            resolve();
          }
        }
        xhr.open('GET', url, true);
        xhr.send();
      });
    },
    isSubscribedToNotification() {
      if (window && window.localStorage && !location.href.includes('smuc.io')) {
        localStorage.setItem('smuc_subscribed_for_' + this.apikey, 1)
        this.subscribedForNotification = true
      }
    },
    eventOnResize() {
      this.windowWidth = window.innerWidth
    },
    customizeViewerBackground(adBackgroundUrl) {
      const $universe = document.querySelector('#smuc-universe');

      if (
        !(
          this.playerMode === 'viewer'
          && typeof adBackgroundUrl == 'string'
          && /^https?/.exec(adBackgroundUrl) != null
          && this.stream.optimize_for_ad
        )
      ) {
        if ($universe) {
          $universe.classList.add('smuc-universe')
          $universe.classList.add('fade-in')
        }
        return
      }

      let css = '.smuc-universe {background:url("' + adBackgroundUrl + '") no-repeat 50% 50%;background-size:cover;}';
      css += '.smuc-universe::before {display:none}';
      const style = document.createElement('style');

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      document.getElementsByTagName('head')[0].appendChild(style);

      $universe.classList.add('smuc-universe')
      $universe.classList.add('fade-in')
    }
  },
}
</script>
